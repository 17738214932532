.admin-page {
  min-height: 100vh;
  width: 100vw;
  background-color: #f0f4f7;
}

.admin-navbar {
  width: 100%;
  background-color: white;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
}

.admin-navbar .left {
  display: flex;
  align-items: center;
  height: 100%;
}

.admin-navbar .left img {
  height: 40px;
}

.admin-sidebar {
  background-color: #1e232e;
  width: 20%;
  height: calc(100vh - 80px);
  position: fixed;
  top: 80px;
  left: 0;
  display: flex;
  flex-direction: column;
}
.admin-sidebar a:first-child {
  margin-top: 4rem;
}

.admin-sidebar a {
  width: 100%;
  margin: 1rem 0;
  padding: 1rem 2rem;
  text-decoration: none;
  color: #666a71;
}

.admin-sidebar a:hover {
  background-color: #575b63;
  color: white;
}

.admin-sidebar a.active {
  color: white;
  border-top: 1px solid #575b63;
  border-bottom: 1px solid #575b63;
}
.admin-content {
  background-color: white;
  width: 76%;
  min-height: 32rem;
  margin-left: 22%;
  margin-top: 110px;
  padding: 25px;
  overflow-y: auto;
}

.table-header {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.table-header > h1 {
  font-size: 25px;
}

.table-header .table-header-actions {
  display: flex;
}

.table-header .table-header-actions .table-header-icon {
  display: flex;
  margin: 0 0.5rem;
  border: 2px solid #000000;
  min-width: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.table-header .table-header-actions .table-header-icon.active {
  background-color: #000000;
  color: #fff;
}

.responsive-table-container {
  width: 100%;
  overflow-x: auto;
}

.responsive-table {
  margin-top: 4rem;
  border-collapse: collapse;
  min-width: 100%;
  width: max-content;
}

.responsive-table-container::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
.responsive-table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.responsive-table-container::-webkit-scrollbar-thumb:horizontal {
  background: #888;
}

/* Handle on hover */
.responsive-table-container::-webkit-scrollbar-thumb:horizontal:hover {
  background: #555;
}

table.responsive-table th,
table.responsive-table td {
  text-align: left;
  padding: 20px 16px;
}

table.responsive-table th.center,
table.responsive-table td.center {
  text-align: center;
}

table.responsive-table tr {
  background: #f0f4f7;
  width: 100%;
  color: #000000;
}

table.responsive-table tr:hover {
  background-color: #000000;
  color: #fff;
}

table.responsive-table .space {
  height: 0.5rem;
  width: 100%;
  background-color: #fff;
}

table.responsive-table .table-popup {
  background-color: white;
  color: #000000;
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
}

table.responsive-table tr.active .table-popup {
  display: block;
}

table.responsive-table .table-popup > * {
  margin: 0.2rem 0;
}

@media screen and (max-width: 992px) {
  .admin-sidebar {
    width: 100%;
    display: none;
  }
  .admin-sidebar.shown {
    display: flex;
  }
  .admin-content {
    width: 100%;
    margin-left: 0%;
  }
  .table-header {
    flex-direction: column;
  }

  .table-header .table-header-actions {
    margin-top: 2rem;
  }

  .table-header .table-header-actions .table-header-icon {
    min-width: 8rem;
  }
}
