.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url("./assets/images/background.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.cursor-pointer {
  cursor: pointer;
}

.App-rooms {
  background-image: url("./assets/images/the-rooms/room-bg.webp");
}

.logo-container {
  height: 160px;
  width: 190px;
  display: flex;
  flex-direction: column;
}

.logo-container .img-container {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.logo-container .img-container img {
  max-width: 75%;
}

.logo-container .bottom {
  flex: 1;
  background-color: transparent;
  display: flex;
}

.triangle-topleft {
  width: 0;
  height: 0;
  border-top: 50px solid white;
  border-right: 95px solid transparent;
}

.triangle-topright {
  width: 0;
  height: 0;
  border-top: 50px solid white;
  border-left: 95px solid transparent;
}

.navbar {
  position: relative;
  min-width: 50%;
}

.links-list {
  display: flex;
  border-top: 1px solid rgb(254, 253, 253);
  border-bottom: 1px solid rgb(254, 253, 253);
  list-style-type: none;
}

.links-list .link-item {
  padding: 0.2rem 5rem;
}

.links-list .link-item.active {
  background: #d9b689;
}

.links-list .link-item a {
  text-decoration: none;
  color: rgb(254, 253, 253);
  font-family: "MuseoSlabW01-100", sans-serif;
}

.nav-toggler {
  display: none;
  position: absolute;
  border: 1px solid rgb(249, 226, 166);
  background: rgb(79, 57, 0);
  padding: 0.5rem;
  top: -5rem;
}

.nav-toggler.right {
  right: -5rem;
}
.nav-toggler.no-right {
  right: -1rem;
}

.nav-toggler svg {
  color: rgb(249, 226, 166);
  font-size: 36px;
  cursor: pointer;
}

.banner {
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  padding-bottom: 1rem;
}

.banner p {
  font-size: 100px;
  line-height: 88px;
  /* margin: 1rem 0; */
  font-weight: 400 !important;
  color: white;
  font-family: "MuseoSlabW01-100", sans-serif;
  text-align: center;
}

.title-component {
  display: flex;
}

.title-component > p {
  color: #4f3900;
  font-size: 40px;
}

.title-component > hr {
  flex: 1;
  border: none;
  border-top: 2px dashed white;
  margin-top: 2rem;
  margin-left: 1rem;
}

hr.horizontal-dashed-line {
  border-right: none;
  border-bottom: none;
  border-left: none;
  border-top: 1.5px dashed white;
}

hr.horizontal-line {
  border-right: none;
  border-bottom: none;
  border-left: none;
  border-top: 1.5px solid white;
}

.title-component-text {
  margin-top: 2rem;
}

.yellow-page {
  margin-top: 0.5rem;
  background-color: rgba(250, 230, 179, 0.8);
  width: 70%;
  padding: 4rem 6rem;
}

.yellow-page.padding-sm {
  padding: 2rem 3rem;
}

.contact-info {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.contact-info .contact-group {
  width: 20%;
  display: flex;
  flex-direction: column;
}

.contact-info .contact-group:first-child {
  width: 35%;
}
.contact-info .contact-group:nth-child(2) {
  width: 30%;
  text-align: center;
  border-right: 2px dashed white;
  border-left: 2px dashed white;
}

.contact-info .contact-group h5 {
  font-size: 14px;
  margin-bottom: 0.3rem;
}

.contact-form-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.contact-form-container .contact-form {
  width: 45%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
}

.contact-form-container .map-area {
  width: 50%;
  padding: 1rem 0;
}

.map {
  display: block;
  width: 100%;
  height: auto;
  min-height: 20rem;
  border: 0;
}

.contact-form-container .contact-form .input {
  width: 100%;
  margin: 0.5rem 0;
  padding: 0.5rem;
  background: rgba(249, 226, 166, 1);
  border: none;
  outline: none;
}

.contact-form-container .contact-form .input::placeholder {
  color: white;
}

.contact-form-container .contact-form input {
  height: 2rem;
}

.contact-form-container .contact-form textarea {
  resize: none;
  height: 8rem;
}

.contact-form-container .contact-form .submit {
  background-color: #f7b800;
  color: #f9e2a6;
  border: none;
  padding: 0.5rem 1rem;
  min-width: 20%;
  cursor: pointer;
}

.subtitle {
  color: rgba(79,57,0,1);
  margin: 2rem 0 1rem 0;
  font-size: 24px;
}

.page-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
}

@media screen and (max-width: 992px) {
  .links-list .link-item {
    padding: 0.2rem 3rem;
  }

  .title-component {
    flex-direction: column;
  }

  .title-component hr {
    margin-left: 0;
    margin-top: 0.5rem;
  }

  .yellow-page {
    width: 100%;
    padding: 1rem;
  }

  .yellow-page.padding-sm {
    padding: 0.5rem;
  }

  .contact-info {
    flex-direction: column;
  }

  .contact-info .contact-group {
    border: none;
    margin: 1rem 0;
    width: 100% !important;
  }
  .contact-info .contact-group:nth-child(2) {
    text-align: left;
    border: none;
  }
  .contact-info .contact-group:nth-child(3) {
    text-align: center;
  }

  .contact-form-container {
    flex-direction: column;
  }
}
@media screen and (max-width: 760px) {
  .contact-form,
  .map-area {
    width: 100% !important;
  }
}
@media screen and (max-width: 760px) {
  .banner p {
    font-size: 3rem;
  }
  .nav-toggler {
    display: inline-block;
  }
  .logo-container {
    margin-right: 4rem;
  }

  .links-list {
    flex-direction: column;
    border: none;
    margin-right: 2rem;
  }

  .links-list.md-hidden {
    display: none;
  }

  .links-list .link-item {
    background: rgb(79, 57, 0);
    opacity: 0.8;
    border: 1px solid rgb(249, 226, 166);
    padding: 0.5rem 10rem 0.5rem 1rem;
  }
  .links-list .link-item.active {
    background: #0b0d11;
    opacity: 1;
  }

  .links-list .link-item a {
    color: rgb(249, 226, 166);
    font-size: medium;
  }
}


.place-book-btn{
  padding: 10px 20px;
  border: 1px solid #0b0d11 ;
  color: #d9b689;
  font-size: 18px;
  border-radius: 6px;
  background-color: #0b0d11;
}