.designer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.designer p {
  width: 60%;
  text-align: center;
}

.designer img {
  width: 30%;
  min-height: 12rem;  
  object-fit: contain;
}

.villa-carousel1 {
  height: 26rem;
  margin: 2rem 0 1rem 0;
}

.villa-carousel2 {
  height: 8rem;
}

.villa-carousel2 img {
  width: 90%;
  height: 100%;
  cursor: pointer;
}

.villa-carousel1 img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.villa-carousel1 .carousel-description {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  font-size: 18px;
  font-family: "MuseoSlabW01-100", sans-serif;
  color: #d1890b;
  background-color: #61400c;
  padding: 0.2rem;
  opacity: 0;
  transition: all 1s ease;
}

.villa-carousel1:hover .carousel-description {
  opacity: 0.95;
}

.villa-carousel2 img:hover {
  opacity: 0.3;
  cursor: pointer;
}

.carousel-modal-close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  color: white;
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}

.villa-carousel-modal div {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.villa-carousel-modal div img {
  width: 50%;
  height: 60%;
  margin-bottom: 1rem;
}

.villa-carousel-modal {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  box-sizing: content-box;
}

@media screen and (max-width: 992px) {
  .designer {
    flex-direction: column;
  }
  .designer p,
  .designer img {
    width: 100%;
    margin-bottom: 1rem;
  }
  .villa-carousel-modal div img {
    width: 80%;
    height: 30%;
    margin-bottom: 1rem;
  }
}
