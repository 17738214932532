.room-details {
  display: flex;
  margin: 3rem 0;
}

.room-details .left {
  width: 70%;
}

.room-details .right {
  width: 30%;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}

.room-details .room-header {
  display: flex;
  align-items: center;
}

.room-details .room-header > svg {
  font-size: 20px;
  margin-right: 1rem;
  cursor: pointer;
}

.room-details .room-header h4 {
  color: #664f17;
  font-size: 20px;
  padding-left: 1rem;
  border-left: 2px solid white;
}

.room-details .room-details-carousel {
  height: 20rem;
  width: 100%;
  margin-top: 1rem;
}

.room-details .room-details-carousel img {
  height: 100rem;
  width: 100%;
  object-fit: contain;
}

.room-details .room-details-carousel-footer {
  width: 100%;
  height: 4rem;
  display: flex;
  margin-top: 1rem;
  overflow-x: auto;
  overflow-y: visible;
}

.room-details .room-details-carousel-footer img {
  width: 90%;
  height: 100%;
  cursor: pointer;
  object-fit: contain;
}

.room-details-row {
  display: flex;
  padding-right: 1rem;
  margin: 2rem 0;
}

.room-details-row.no-margin {
  margin: 0;
}

.room-details-row h5 {
  width: 35%;
  color: #4f3900;
  font-size: 18px;
}

.room-details-row > *:nth-child(2) {
  flex: 1;
}

.room-details-row p,
.room-details-row a {
  color: #715c24;
}

.room-details-row .amenities > p {
  margin: 1rem 0;
  display: flex;
  align-items: center;
}
.room-details-row .amenities svg {
  font-size: 20px;
  margin-right: 1rem;
}

.room-details-row .amenities.large svg {
  font-size: 40px;
  margin-right: 1rem;
}

.room-details-filters {
  border: 1px solid white;
  width: 90%;
  padding: 1.5rem;
}

.room-details-filters .info {
  border-left: 1px solid white;
  padding-left: 1rem;
}

.room-details-filters button,
.room-details .button {
  color: #f9e2a6;
  background-color: #000000;
  padding: 0.5rem 2rem;
  border: none;
  cursor: pointer;
  opacity: 1;
  width: 100%;
  margin-top: 2rem;
}

.room-details-filters .inputs {
  margin-top: 2rem;
  background-color: white;
  padding: 0.3rem;
}

.room-details-filters .inputs > * {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.room-details-filters .inputs .filter-container {
  border: none;
}

.room-details-filters .inputs .filterInput,
.room-details-filters .inputs .date-pickers > * {
  width: 100%;
  margin: 0.5rem 0;
}

.room-details-filters .inputs .date-pickers .react-datepicker-wrapper,
.room-details-filters
  .inputs
  .date-pickers
  .react-datepicker-wrapper
  .react-datepicker__input-container {
  height: 100%;
  cursor: pointer;
}

.room-details-filters .inputs .date-pickers .custom-date-input-rooms {
  height: 100%;
  background-color: white;
  padding: 0 0.5rem;
}

.room-details-filters .inputs .date-pickers .custom-date-input-rooms .value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
}

.room-list-item .room-info-md {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 1rem;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
}

.room-list-item {
  width: 100%;
  height: 15rem;
  position: relative;
  margin-top: 2rem;
}

.room-list-item > img {
  width: 100%;
  height: 100%;
}

.room-list-item .room-info-md > * {
  width: 50%;
  color: white;
}

.room-list-item .room-info-md > h6 {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.room-list-item .room-info-md > h6 > * {
  color: white !important;
}

.room-details.small {
  display: none;
}

@media screen and (max-width: 992px) {
  .room-details.large {
    display: none;
  }
  .room-details.small {
    display: block;
  }
}
