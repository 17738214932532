.rooms-filters {
  display: flex;
  margin: 2rem 0;
}

.rooms-filters .date-pickers {
  width: 40%;
}

.rooms-filters .filters-inputs {
  width: 40%;
}

.rooms-filters .filter-submit {
  width: 20%;
}

.rooms-filters .filterInput {
  width: 50%;
  margin: 0;
}

.rooms-filters .filterInput .filter-container {
  width: 100%;
  border: none;
  height: 100%;
}

.rooms-filters .filterInput .filter-container p {
  font-size: 16px;
}

.rooms-filters .date-pickers .react-datepicker-wrapper,
.rooms-filters
  .date-pickers
  .react-datepicker-wrapper
  .react-datepicker__input-container {
  height: 100%;
  cursor: pointer;
}

.rooms-filters .date-pickers .custom-date-input-rooms {
  height: 100%;
  background-color: white;
  padding: 0 0.5rem;
}

.rooms-filters .date-pickers .custom-date-input-rooms .value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.rooms-list {
  margin: 2rem 0;
}

.rooms-list-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 2rem 0;
}

.rooms-list-item > div:nth-child(2) .amenities {
  margin-top: 1rem;
  display: flex;
}

.rooms-list-item > div:nth-child(2) .amenities svg {
  color: #715c24;
}

.rooms-list-item > div:nth-child(2) .amenities > * {
  width: 1.3rem;
  height: 1.3rem;
  color: #14120d;
  margin: 0 0.4rem;
}

.rooms-list-item > div:nth-child(2) {
  flex: 1;
  padding: 0 1rem;
  width: 10rem;
}

.rooms-list-item > div:nth-child(2) h5 {
  color: #523c03;
  font-size: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rooms-list-item > div:nth-child(2) p {
  color: rgba(79, 57, 0, 1);
  opacity: 1;
  margin-top: 0.3rem;
  font-size: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rooms-list-item > div:nth-child(2) li {
  color: rgba(79, 57, 0, 1);
  margin-top: 0.3rem;
  margin-bottom: 1rem;
  font-size: 15px;
}

.rooms-list-item div:first-child {
  position: relative;
}

.rooms-list-item div:first-child a {
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.699);
  padding: 0.25rem 1rem;
  border: none;
  cursor: pointer;
  opacity: 0;
  text-decoration: none;
  position: absolute;
  bottom: 0.2rem;
  right: 0;
  transition: all .48s ease-in-out;
}

.rooms-list-item div:first-child:hover a {
  opacity: 1;
}

.rooms-list-item div:first-child img {
  width: 15rem;
  height: 10rem;
}

.rooms-list-item div:nth-child(3) {
  min-width: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rooms-list-item div:nth-child(3) p {
  color: #86713a;
}

.rooms-list-item div:nth-child(3) h6 {
  color: #4f3900;
  font-size: 30px;
  margin: 1rem 0;
}

.rooms-list-item div:nth-child(3) a {
  color: rgba(249,226,166,1);
  background-color: #000000;
  padding: 0.5rem 2rem;
  border: none;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
}

.rooms-list-item div:nth-child(1) .room-info-md {
  display: none;
}

@media screen and (max-width: 992px) {
  .rooms-filters {
    flex-direction: column;
  }

  .rooms-filters > * {
    width: 100% !important;
  }

  .rooms-filters .filterInput,
  .rooms-filters .date-pickers > * {
    width: 100%;
    margin: 0.5rem 0;
  }

  .rooms-filters
    .date-pickers
    .react-datepicker-wrapper
    .react-datepicker__input-container {
    height: 2rem;
  }

  .rooms-list-item div:first-child img {
    width: 100%;
    min-height: 10rem;
  }

  .rooms-list-item {
    flex-direction: column;
  }

  .rooms-list-item div:first-child {
    display: flex;
  }

  .rooms-list-item div:first-child a {
    display: none;
  }

  .rooms-list-item div:nth-child(2),
  .rooms-list-item div:nth-child(3) {
    display: none;
  }

  .rooms-list-item div:first-child .room-info-md {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
  }

  .rooms-list-item div:first-child .room-info-md > * {
    width: 40%;
    color: white;
  }

  .rooms-list-item div:first-child .room-info-md > h6 {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .rooms-list-item div:first-child .room-info-md > h6 > * {
    color: white !important;
  }
}
