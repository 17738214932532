.home-filters {
  background-color: rgba(250, 230, 179, 0.8);
  opacity: 0.95;
  display: flex;
  padding: 0.5rem 2rem;
  align-items: flex-end;
  margin-top: 5rem;
}

.filters-inputs {
  display: flex;
}

.filterInput {
  margin: 0 1rem;
}

.filter-container {
  border: 1px solid #737373;
  display: flex;
  min-width: 7rem;
  align-items: center;
  padding: 0 2px 0 6px;
  background-color: white;
}

.filter-container > p {
  flex: 1;
  font-size: 20px;
}

.filter-container .controls {
  display: flex;
  flex-direction: column;
}

.filter-container .controls > svg {
  cursor: pointer;
}

.filter-container .controls > svg.disabled {
  color: gray;
}

.filter-label {
  color: #665019;
}

.filter-submit {
  color: rgba(249,226,166,1) !important;
  background-color: #000000;
  padding: 0.5rem 2rem;
  border: none;
  cursor: pointer;
  opacity: 1;
}

.custom-date-input {
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem;
}

.custom-date-input .value {
  display: flex;
  align-items: center;
  background-color: white;
  min-width: 14rem;
  padding: 6px;
}

.custom-date-input .value > p {
  flex: 1;
}

.date-pickers {
  display: flex;
}

@media screen and (max-width: 992px) {
  .home-filters {
    flex-direction: column;
    align-items: center;
  }
  .filters-inputs {
    flex-direction: column;
  }
  .filter-submit {
    margin-top: 1rem;
  }

  .custom-date-input {
    margin: 1rem 0;
  }
  .filterInput {
    margin: 1rem 0;
  }

  .filter-container {
    min-width: 14rem;
  }

  .date-pickers {
    flex-direction: column;
  }

  .filter-submit {
    min-width: 14rem;
  }
}
