@font-face { 
    font-family: "Niconne";
    src:local("Niconne"), url('../assets/fonts/Niconne/Niconne-Regular.ttf');
    font-weight: 400;
}@font-face { 
    font-family: "Poppins";
    src:local("Poppins"), url('../assets/fonts/Poppins//Poppins-Light.ttf');
    font-weight: 300;
}@font-face { 
    font-family: "MuseoSlabW01-100";
    src:local("MuseoSlabW01-100"), url('../assets/fonts/Museo_Slab/Museo-Slab-W01-100.ttf');
    font-weight: 400;
}
@font-face { 
    font-family: "openSans";
    src:local("openSans"), url('../assets/fonts/Open_Sans/OpenSans-Regular.ttf');
    font-weight: 400;
}
@font-face { 
    font-family: "openSansLight";
    src:local("openSansLight"), url('../assets/fonts/Open_Sans/OpenSans-Light.ttf');
    font-weight: 400;
}@font-face { 
    font-family: "openSansBold";
    src:local("openSansBold"), url('../assets/fonts/Open_Sans/OpenSans-Bold.ttf');
    font-weight: 800;
}@font-face { 
    font-family: "openSansSemiBold";
    src:local("openSansSemiBold"), url('../assets/fonts/Open_Sans/OpenSans-SemiBold.ttf');
    font-weight: 700;
}

* {
  box-sizing: border-box;
  transition: all 0.2 ease;
  font-family: "openSans",sans-serif;
}

.font-poppins{
    font-family: "Poppins",serif;
}
.font-niconne{
    font-family: "Niconne",serif;
}
.font-light{
    font-family: "openSansLight";
}
.font-semibold{
    font-family: "openSansSemiBold";
}
.font-bold{
    font-family: "openSansBold";
}

.font-arial{
    font-family: Arial, Helvetica, sans-serif;
}

.font-16{
    font-size: 16px !important;
}