.avatar{
    width:60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: bolder;
    color: #fff;
    border-radius: 50%;
}