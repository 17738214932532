.register-page {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 0;
}

.register-form-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem;
}

.register-logo-container {
  background-color: white;
  padding: 5rem 2rem;
}

.register-form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-form-container form {
  background-color: rgba(250, 230, 179, 0.8);
  width: 90%;
  padding: 2rem;
}

.register-form-container form > h1 {
  margin: 2rem 0;
}

.register-form-container form > .input-container input,
.input-container button {
  width: 100%;
  margin: 0.5rem 0;
  padding: 0.5rem;
  background: white;
  border: none;
  outline: none;
  height: 2.5rem;
  margin-bottom: 1rem;
}

.register-form-container form > .input-container button {
  background: black;
  color: #f9e2a6;
  cursor: pointer;
}

.register-form-container form .link {
  display: flex;
  justify-content: center;
}

.register-form-container form .link a {
  margin-left: 1rem;
  color: black;
  font-weight: bold;
}

@media screen and (min-width: 992px) {
  .register-page {
    flex-direction: row;
  }

  .register-form-container {
    width: 50%;
  }

  .register-form-logo {
    width: 50%;
  }
}

@media screen and (min-width: 1100px){
  .register-form-container form{
    width: 60%;
  }
}