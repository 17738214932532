.chats-box{
    border-right: "2px solid #707070";
    width: "33%";
}
.single-chat:hover{
    background-color: #F0F4F7;
}
.chat-details-box{
    height: 600px;
    overflow-y: auto;
    width:66%
}

.flex{
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.gap-2{
    column-gap: 10px;
}

.everything-center{
    align-items: center;
    justify-content: center;
}

.bg-light-blue{
    background-color: #E6EAEF;
    height: inherit;
    padding:15px;
}

.contact-name{
    font-size: 17px;
}

.py-2{
    padding-top: 10px;
    padding-bottom: 10px;
}

.py-2 p{
    font-size: 17.5px;
}

.parent{
height: 100%;
}
.chat-message{
    height: 85%;
    overflow-y: auto;

}
.reply{
    height: 15%;
    padding:15px 0;
}
.reply div{
    border: 1px solid #707070;
    padding:10px 20px;
    display: flex;
}

.reply div input{
    display: block;
    width: 85%;
    font-size: 17px;
    padding: 10px 0;
    padding-right:20px;
    border: none;
    outline: none;
    box-sizing: border-box;
}

.reply div button{
    background-color: #1E232E;
    cursor: pointer;
    padding: 7px 20px;
    outline: none;
    border: none;
    color: white;
    font-size: 15px;
    width: 15%;
    display: inline-block;
}