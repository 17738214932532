.footer {
  background-color: rgba(246, 236, 222, 0.6);
  padding: 4rem 2rem;
  margin: 2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 2px solid #d29c00;
}

.footer-content {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
}

.footer-content .footer-group {
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
}

.footer-content .footer-group .title {
  font-family: "Niconne", cursive;
  text-align: center;
  /* font-weight: 600; */
  text-decoration: underline;
  font-size: 20px;
  padding-bottom: 5px;
}

.footer-content .footer-group span a {
  text-decoration: none;
  color: black;
}

.social-icons .icons {
  display: flex;
  justify-content: center;
}

.social-icons .icons a {
  margin: 0 0.5rem;
}

.social-icons .icons a img {
  width: 25px;
  height: 25px;
  background-color: white;
}

.social-icons .copyright {
  margin-top: 1rem;
}

@media screen and (max-width: 992px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-content .footer-group span {
    text-align: center;
  }

  .footer-content .footer-group {
    margin: 0.5rem 0;
  }
}
